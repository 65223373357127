*, :after, :before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: #606c76;
  letter-spacing: .01em;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.6;
}

blockquote {
  border-left: .3rem solid #d1d1d1;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
}

blockquote :last-child {
  margin-bottom: 0;
}

.button, button, input[type="button"], input[type="reset"], input[type="submit"] {
  color: #fff;
  cursor: pointer;
  letter-spacing: .1rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: #9b4dca;
  border: .1rem solid #9b4dca;
  border-radius: .4rem;
  height: 3.8rem;
  padding: 0 3rem;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 3.8rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus, .button:hover, button:focus, button:hover, input[type="button"]:focus, input[type="button"]:hover, input[type="reset"]:focus, input[type="reset"]:hover, input[type="submit"]:focus, input[type="submit"]:hover {
  color: #fff;
  background-color: #606c76;
  border-color: #606c76;
  outline: 0;
}

.button[disabled], button[disabled], input[type="button"][disabled], input[type="reset"][disabled], input[type="submit"][disabled] {
  cursor: default;
  opacity: .5;
}

.button[disabled]:focus, .button[disabled]:hover, button[disabled]:focus, button[disabled]:hover, input[type="button"][disabled]:focus, input[type="button"][disabled]:hover, input[type="reset"][disabled]:focus, input[type="reset"][disabled]:hover, input[type="submit"][disabled]:focus, input[type="submit"][disabled]:hover {
  background-color: #9b4dca;
  border-color: #9b4dca;
}

.button.button-outline, button.button-outline, input[type="button"].button-outline, input[type="reset"].button-outline, input[type="submit"].button-outline {
  color: #9b4dca;
  background-color: #0000;
}

.button.button-outline:focus, .button.button-outline:hover, button.button-outline:focus, button.button-outline:hover, input[type="button"].button-outline:focus, input[type="button"].button-outline:hover, input[type="reset"].button-outline:focus, input[type="reset"].button-outline:hover, input[type="submit"].button-outline:focus, input[type="submit"].button-outline:hover {
  color: #606c76;
  background-color: #0000;
  border-color: #606c76;
}

.button.button-outline[disabled]:focus, .button.button-outline[disabled]:hover, button.button-outline[disabled]:focus, button.button-outline[disabled]:hover, input[type="button"].button-outline[disabled]:focus, input[type="button"].button-outline[disabled]:hover, input[type="reset"].button-outline[disabled]:focus, input[type="reset"].button-outline[disabled]:hover, input[type="submit"].button-outline[disabled]:focus, input[type="submit"].button-outline[disabled]:hover {
  border-color: inherit;
  color: #9b4dca;
}

.button.button-clear, button.button-clear, input[type="button"].button-clear, input[type="reset"].button-clear, input[type="submit"].button-clear {
  color: #9b4dca;
  background-color: #0000;
  border-color: #0000;
}

.button.button-clear:focus, .button.button-clear:hover, button.button-clear:focus, button.button-clear:hover, input[type="button"].button-clear:focus, input[type="button"].button-clear:hover, input[type="reset"].button-clear:focus, input[type="reset"].button-clear:hover, input[type="submit"].button-clear:focus, input[type="submit"].button-clear:hover {
  color: #606c76;
  background-color: #0000;
  border-color: #0000;
}

.button.button-clear[disabled]:focus, .button.button-clear[disabled]:hover, button.button-clear[disabled]:focus, button.button-clear[disabled]:hover, input[type="button"].button-clear[disabled]:focus, input[type="button"].button-clear[disabled]:hover, input[type="reset"].button-clear[disabled]:focus, input[type="reset"].button-clear[disabled]:hover, input[type="submit"].button-clear[disabled]:focus, input[type="submit"].button-clear[disabled]:hover {
  color: #9b4dca;
}

code {
  white-space: nowrap;
  background: #f4f5f6;
  border-radius: .4rem;
  margin: 0 .2rem;
  padding: .2rem .5rem;
  font-size: 86%;
}

pre {
  background: #f4f5f6;
  border-left: .3rem solid #9b4dca;
  overflow-y: hidden;
}

pre > code {
  white-space: pre;
  border-radius: 0;
  padding: 1rem 1.5rem;
  display: block;
}

hr {
  border: 0;
  border-top: .1rem solid #f4f5f6;
  margin: 3rem 0;
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="week"], input:not([type]), textarea, select {
  -webkit-appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  background-color: #0000;
  border: .1rem solid #d1d1d1;
  border-radius: .4rem;
  width: 100%;
  height: 3.8rem;
  padding: .6rem 1rem .7rem;
}

input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus, select:focus {
  border-color: #9b4dca;
  outline: 0;
}

select {
  background: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 8\" width=\"30\"><path fill=\"%23d1d1d1\" d=\"M0,0l6,8l6-8\"/></svg>") 100% no-repeat;
  padding-right: 3rem;
}

select:focus {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 8\" width=\"30\"><path fill=\"%239b4dca\" d=\"M0,0l6,8l6-8\"/></svg>");
}

select[multiple] {
  background: none;
  height: auto;
}

textarea {
  min-height: 6.5rem;
}

label, legend {
  margin-bottom: .5rem;
  font-size: 1.6rem;
  font-weight: 700;
  display: block;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  display: inline;
}

.label-inline {
  margin-left: .5rem;
  font-weight: normal;
  display: inline-block;
}

.container {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.row {
  flex-direction: column;
  width: 100%;
  padding: 0;
  display: flex;
}

.row.row-no-padding, .row.row-no-padding > .column {
  padding: 0;
}

.row.row-wrap {
  flex-wrap: wrap;
}

.row.row-top {
  align-items: flex-start;
}

.row.row-bottom {
  align-items: flex-end;
}

.row.row-center {
  align-items: center;
}

.row.row-stretch {
  align-items: stretch;
}

.row.row-baseline {
  align-items: baseline;
}

.row .column {
  flex: auto;
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  display: block;
}

.row .column.column-offset-10 {
  margin-left: 10%;
}

.row .column.column-offset-20 {
  margin-left: 20%;
}

.row .column.column-offset-25 {
  margin-left: 25%;
}

.row .column.column-offset-33, .row .column.column-offset-34 {
  margin-left: 33.3333%;
}

.row .column.column-offset-40 {
  margin-left: 40%;
}

.row .column.column-offset-50 {
  margin-left: 50%;
}

.row .column.column-offset-60 {
  margin-left: 60%;
}

.row .column.column-offset-66, .row .column.column-offset-67 {
  margin-left: 66.6666%;
}

.row .column.column-offset-75 {
  margin-left: 75%;
}

.row .column.column-offset-80 {
  margin-left: 80%;
}

.row .column.column-offset-90 {
  margin-left: 90%;
}

.row .column.column-10 {
  flex: 0 0 10%;
  max-width: 10%;
}

.row .column.column-20 {
  flex: 0 0 20%;
  max-width: 20%;
}

.row .column.column-25 {
  flex: 0 0 25%;
  max-width: 25%;
}

.row .column.column-33, .row .column.column-34 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.row .column.column-40 {
  flex: 0 0 40%;
  max-width: 40%;
}

.row .column.column-50 {
  flex: 0 0 50%;
  max-width: 50%;
}

.row .column.column-60 {
  flex: 0 0 60%;
  max-width: 60%;
}

.row .column.column-66, .row .column.column-67 {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
}

.row .column.column-75 {
  flex: 0 0 75%;
  max-width: 75%;
}

.row .column.column-80 {
  flex: 0 0 80%;
  max-width: 80%;
}

.row .column.column-90 {
  flex: 0 0 90%;
  max-width: 90%;
}

.row .column .column-top {
  align-self: flex-start;
}

.row .column .column-bottom {
  align-self: flex-end;
}

.row .column .column-center {
  align-self: center;
}

@media (width >= 40rem) {
  .row {
    flex-direction: row;
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }

  .row .column {
    margin-bottom: inherit;
    padding: 0 1rem;
  }
}

a {
  color: #9b4dca;
  text-decoration: none;
}

a:focus, a:hover {
  color: #606c76;
}

dl, ol, ul {
  margin-top: 0;
  padding-left: 0;
  list-style: none;
}

dl dl, dl ol, dl ul, ol dl, ol ol, ol ul, ul dl, ul ol, ul ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%;
}

ol {
  list-style: decimal inside;
}

ul {
  list-style: circle inside;
}

.button, button, dd, dt, li {
  margin-bottom: 1rem;
}

fieldset, input, select, textarea {
  margin-bottom: 1.5rem;
}

blockquote, dl, figure, form, ol, p, pre, table, ul {
  margin-bottom: 2.5rem;
}

table {
  border-spacing: 0;
  text-align: left;
  width: 100%;
  display: block;
  overflow-x: auto;
}

td, th {
  border-bottom: .1rem solid #e1e1e1;
  padding: 1.2rem 1.5rem;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

@media (width >= 40rem) {
  table {
    overflow-x: initial;
    display: table;
  }
}

b, strong {
  font-weight: bold;
}

p {
  margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: -.1rem;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}

h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}

h4 {
  letter-spacing: -.08rem;
  font-size: 2.2rem;
  line-height: 1.35;
}

h5 {
  letter-spacing: -.05rem;
  font-size: 1.8rem;
  line-height: 1.5;
}

h6 {
  letter-spacing: 0;
  font-size: 1.6rem;
  line-height: 1.4;
}

img {
  max-width: 100%;
}

.clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}
/*# sourceMappingURL=index.dd0450bf.css.map */
